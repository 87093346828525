// Buttons Loader
@keyframes flash {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
/*
==============================================
pulse
==============================================
*/

.pulse {
	animation-name: pulse;
	-webkit-animation-name: pulse;

	animation-duration: 1.5s;
	-webkit-animation-duration: 1.5s;

	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.9);
		opacity: 0.7;
	}
	50% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(0.9);
		opacity: 0.7;
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;
	}
	50% {
		-webkit-transform: scale(1);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(0.95);
		opacity: 0.7;
	}
}


