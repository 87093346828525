// Profile
.reg-profile {
  a {
    text-decoration: none;
  }
  .reg-subscription {
    margin-bottom: 66px;
  }
  .reg-forms {
    margin-top: 0;
  }

  // Change password
  .reg-forget-status {
    font-size: 20px;
  }
}

// Navs
.reg-profile-navs {
  line-height: 42px;
  margin-top: 15px;

  a {
    color: $color-green;
  }
  span {
    padding-left: 10px;
    padding-right: 10px;
  }
}

// Description
.prof-dec {
  margin-bottom: 39px;

  &:last-child {
    margin-bottom: 0;
  }
}
.prof-dec-title {
  font-size: 16px;
  margin-bottom: 7px;
  color: #464646;
}
.prof-dec-description {
  font-size: 20px;
  line-height: 1;
}

// Unsubscribe
.prof-unsubscription {
  h3 {
    margin-bottom: 16px;
  }
}

// Profile change password
.prof-form {
  .prof-inner {
    display: none;
  }
  .divider {
    margin-top: 0;
    margin-bottom: 17px;
    border-color: #000;
  }
  &.form-visible {
    .prof-inner {
      display: block;
    }
    #prof-show-form {
      display: none;
    }
  }
}
.prof-form-title {
  text-align: justify;
  font-size: 0;
  line-height: 1;

  &:after {
    content: '';
    width: 100%;
    display: inline-block;
  }
  h4,
  p {
    display: inline-block;
    vertical-align: middle;
  }
  h4 {
    font-size: 20px;
    color: #464646;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    color: #fd5d74;
    cursor: pointer;
  }
}

@media (min-width: $screen-sm-min) {
  .reg-profile-navs {
    margin-top: 0;
  }
}
