// General Styles
// -- Section Meditation
.sec-meditation {
  position: relative;
  z-index: 2;
  overflow: visible;
  background: transparent url('../img/meditation-circle.png') no-repeat;
  background-size: contain;
  background-position: 50% 50%;

  .col-xs-12 {
    overflow: hidden;
  }
  .scroll-top-down {
    position: absolute;
    z-index: 2;
    top: 38px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    height: 46px;
    cursor: pointer;
   }
  .meditation-left {
    h2 {
      font-size: 25px;
      text-transform: uppercase;
      margin-bottom: 2px;
    }
    p {
      font-size: 16px;
      line-height: 1;
      font-weight: 300;
    }
  }
  .col-inner-img {
    animation-duration: 2s;
    margin-top: -40px;
  }
  .col-inner-icons {
    position: absolute;
    width: 400px;
    height: 400px;
    left: 50%;
    top: 50%;
    margin-top: -200px;
    transform: translate(-50%, 0);
  }
  .spr-1 {
    width: 65px;
    height: 63px;
    background-position: -22px 0;
  }
  .col-inner-icons span {
    position: absolute;
  }
  .icon-1 {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .spr-2 {
    width: 54px;
    height: 63px;
    background-position: -89px 0;
  }
  .icon-2 {
    top: 61px;
    right: 56px;
  }
  .spr-3 {
    width: 48px;
    height: 73px;
    background-position: -145px 0;
  }
  .icon-3 {
    top: 50%;
    right: 0;
    transform: translateY(-70%);
  }
  .spr-4 {
    background-position: -194px 0;
    width: 72px;
    height: 65px;
  }
  .icon-4 {
    bottom: 68px;
    right: 44px;
  }
  .spr-5 {
    width: 73px;
    height: 51px;
    background-position: -268px 0;
  }
  .icon-5 {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .spr-6 {
    width: 64px;
    height: 63px;
    background-position: -343px 0;
  }
  .icon-6 {
    bottom: 60px;
    left: 88px;
    transform: translateX(-50%);
  }
  .spr-7 {
    width: 69px;
    height: 51px;
    background-position: -409px 0;
  }
  .icon-7 {
    top: 50%;
    left: 0;
    transform: translateY(-47%);
  }
  .spr-8 {
    width: 55px;
    height: 67px;
    background-position: -480px 0;
  }
  .icon-8 {
    top: 68px;
    left: 46px;
  }
}

// -- Section Deep Sleep
.sec-deep-sleep {
  background-image: url('../img/sleep-deep.jpg');

  .col-deep-center {
    margin-bottom: 40px;
  }
  .col-inner-images {
    font-size: 0;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;
    text-align: center;
  }
  .deep-images {
    display: inline-block;
    width: 90px;
    height: 90px;
    margin: 2px;

    img {
      border-radius: 50%;
      border: 4px solid $color-white;
    }
  }
  .col-message {
    background-color: $color-white;
    padding: 16px 25px;
    border-radius: 10px;
    margin-bottom: 30px;
    max-width: 300px;
  }
}

// -- Section Features
.sec-features {
  background-image: url('../img/features-bg.png');
  border-bottom: 8px solid #e7e7e7;

  .col-content-head {
    margin-bottom: 35px;

    h3 {
      font-size: 32px;
      text-transform: uppercase;
      color: #b6b6b6;
    }
  }
  .features-cols {
    margin-bottom: 26px;

    &:last-child {
      margin-bottom: 0;
    }
  }
  .features-icons,
  .features-text {
    vertical-align: middle;
  }
  .features-text {
    padding-left: 20px;
    width: calc(100% - 63px - 20px);

    p {
      font-size: 24px;
    }
  }
  .features-icons {
    width: 63px;

    span {
      width: 63px;
      height: 63px;
      background-color: #27d2bd;
      border-radius: 63px;
      position: relative;
      display: block;
    }
    i {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .spr-1 {
      width: 25px;
      height: 30px;
      background-position: -537px 0;
    }
    .spr-2 {
      width: 28px;
      height: 22px;
      background-position: -564px 0;
    }
    .spr-3 {
      width: 31px;
      height: 24px;
      background-position: -594px 0;
    }
    .spr-4 {
      width: 35px;
      height: 22px;
      background-position: -627px 0;
    }
  }
  .features-image {
    margin-top: 40px;
  }
}

// -- Section Relaxatin Pillow
.sec-relaxion-pillow {
  border-top: 8px solid #f4f4f4;
  border-bottom: 8px solid #f4f4f4;
  background-image: url('../img/relax-pillow-bg.png');

  .col-inner-content {
    &:first-child {
      text-align: center;
      margin-bottom: 40px;
      position: relative;
      z-index: 3;
    }
  }
  .col-content {
    position: relative;
    z-index: 2;

    h4 {
      position: relative;
      z-index: 3;
      font-size: 32px;
      margin-bottom: 13px;
      text-transform: uppercase;
    }
    p {
      position: relative;
      z-index: 3;
      font-size: 16px;
    }
  }
}

// --- Section Ipad Support
.sec-ipad-support {
  background-image: url('../img/suport-bg.jpg');
  padding-bottom: 0;

  .row {
    font-size: 0;
  }
  .col-support-text {
    color: $color-white;

    h3 {
      font-size: 32px;
      text-transform: uppercase;
      margin-bottom: 13px;
    }
    p {
      font-size: 16px;
    }
  }
}

// --- Section AppleTv Suport
.sec-appletv-support {
  .col-support-video {
    margin-bottom: 36px;
  }
  .col-support-img {
    margin-bottom: 40px;
  }
  h4 {
    text-transform: uppercase;
    font-size: 32px;
    margin-bottom: 15px;
  }
}

// --- Section Apple Watch Support
.sec-apple-watch-support {
  background-image: url('../img/support-apple-watch-bg.png');

  h4 {
    text-transform: uppercase;
    margin-bottom: 15px;
    font-size: 32px;
    color: $color-white;
  }
  p {
    color: $color-white;
  }
}

// --- Section Copyright
.sec-copyright-audio {
  $playerHover: #5ff192;
  background-image: url('../img/copyright-bg.jpg');
  text-align: center;

  &.sec-audio-download {
    .copyright-audio-content {
      transition: all 0.7s linear;
      visibility: hidden;
      opacity: 0;
    }
    .copyright-audio-download {
      .col-xs-12 {
        transition: all 0.7s linear;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .copyright-audio-content {
    transition: all 0.7s linear;
  }
  .col-left {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }
  .players-slider {
    margin-left: 60px;
    font-size: 0;

    i {
      font-size: 16px;
      vertical-align: middle;
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .players-audio {
    margin-bottom: 40px;
  }
  .round {
    width: 90px;
    height: 90px;
    border-radius: 90px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s linear;
    margin-left: auto;
    margin-right: auto;

    .icon {
      background: #fff;
      position: relative;

      &,
      &:after,
      &:before {
        transition: all 0.5s ease-in-out;
      }
    }
    &:hover {
      background-color: $playerHover;
      transition: background-color 0.2s linear;

      .icon {
        &:after {
          border-left-color: $color-white;
        }
      }
    }
    &:after,
    &:before {
      transition: all 0.35s ease-in-out;
      opacity: 0;
    }
    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 50px;
      position: absolute;
      background: rgba(255, 255, 255, 0.3);
      border-radius: 50%;
      top: 50%;
      left: 50%;
      margin-left: -25px;
      margin-top: -25px;
    }
    &.active {
      &:after,
      &:before {
        animation: flash 0.35s ease-in-out;
      }
      &:after {
        width: 100px;
        height: 100px;
      }
      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        background: rgba(255, 255, 255, 0.3);
      }
      &:after {
        margin-left: -75px;
        margin-top: -75px;
      }
    }
  }
  .play {
    background: $color-white;
    box-shadow: 0px 6px 16px 0px rgba(36, 148, 188, 1);

    .icon {
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      display: block;

      &:after,
      &:before {
        content: '';
        display: block;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 18px solid #5ff192;
        position: absolute;
        top: -15px;
        left: -4px;
      }
      &:before {
        border-width: 0;
      }
    }
    &.playning {
      &:hover {
        .icon {
          &:after,
          &:before {
            border-left-color: $color-white;
          }
        }
      }
      .icon {
        transform: rotate(180deg);

        &:after,
        &:before {
          height: 26px;
          border: 0;
          border-color: transparent
        }
        &:after {
          top: -13px;
          left: 3px;
          border-left: 7px solid $playerHover;
        }
        &:before {
          top: -13px;
          left: -10px;
          border-left: 7px solid $playerHover;
        }
      }
    }
  }
  .ui-slider {
    height: 4px;
    width: 180px;
    background: rgba(0, 0, 0, 0.4);
    border: none;
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    margin-right: 15px;

    span {
      width: 18px;
      height: 18px;
      border: 5px solid $color-white;
      background-color: $playerHover;
      border-radius: 20px;
      top: 50%;
      transform: translate(-50%, -9px);
      outline: none;
    }
  }
  .players-stations {
    margin-bottom: 40px;

    ul {
      padding: 0;
      font-size: 0;
      margin-left: -19px;
      margin-right: -19px;
    }
    li {
      display: inline-block;
      vertical-align: middle;
      padding-left: 19px;
      padding-right: 19px;

      &.active {
        span {
          border-color: $playerHover;

          &:after {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
    span {
      display: block;
      width: 142px;
      height: 142px;
      border-radius: 142px;
      overflow: hidden;
      border: 2px solid transparent;
      position: relative;

      &:after {
        content: '';
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
        z-index: 1;
        border-radius: 50%;
      }
      img {
        padding: 4px;
        border-radius: 50%;
        position: relative;
        z-index: 2;
      }
    }
  }
  .col-right {
    .col-inner {
      max-width: 350px;
      margin-left: auto;
      margin-right: auto;
    }
    ul {
      font-size: 0;
      margin-left: -2px;
      margin-right: -2px;
      margin-bottom: -4px;
    }
    li {
      display: inline-block;
      vertical-align: middle;
      padding-left: 2px;
      padding-right: 2px;
      padding-bottom: 4px;

      &.active {
        span {
          border-color: $playerHover;

          &:after {
            background-color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
    span {
      width: 68px;
      height: 68px;
      display: block;
      border: 2px solid transparent;
      border-radius: 68px;
      position: relative;

      &:after {
        content: '';
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
        z-index: 1;
        border-radius: 50%;
      }
    }
    img {
      border-radius: 72px;
      padding: 2px;
      position: relative;
      z-index: 2;
    }
  }
  .copyright-audio-download {
    .col-xs-12 {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s linear;
    }

    h5 {
      font-weight: bold;
      font-size: 28px;
      color: $color-white;
      margin-bottom: 24px;
    }
    .buttons-app {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 10px;
    }
    .copyright-audio-continue {
      font-size: 14px;
      color: $color-black;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

// Responsive
@media (max-width: 1024px) {
  .sec-meditation {
    background-position: 75% 50%;
  }
}

@media (max-width: $screen-sm-max) {
  .sec-meditation {
    background: none;

    .table,
    .table-cell {
      display: block;
    }
    .meditation-center {
      height: 400px;
    }
    .col-inner-img {
      margin-top: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .meditation-left {
      .col-content {
        text-align: center;
        padding-top: 50px;
      }
    }
  }
  .sec-deep-sleep .col-inner-content {
    text-align: center;
  }
}

@media (max-width: $screen-ms-max) {
  .sec-meditation {
    background: none;

    .table {
      display: block;
    }
    .table-cell {
      display: block;
    }
    .meditation-left {
      .col-content {
        padding-top: 50px;
        margin-bottom: 15px;
      }
    }
    .meditation-center {
      height: 450px;
      position: relative;
    }
    .sprites {
      transform: scale(0.7);
    }
  }
  .sec-ipad-support {
    .col-support-text {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .sec-meditation {
    background-position: 50% 58%;

    .meditation-center {
      height: 400px;
    }
    .col-inner-icons {
      width: 315px;
      height: 315px;
      margin-top: -150px;
    }
    .col-inner-img {
      max-width: 100px;
    }
  }
  .sec-deep-sleep {
    .col-message {
      max-width: 100%;
    }
  }
  .sec-copyright-audio {
    .play {
      margin-bottom: 40px;
    }
    .players-slider {
      margin-left: 0;
    }
  }
}

@media (min-width: $screen-ms-min) {
  .sec-deep-sleep {
    .col-deep-left {
      .col-message:nth-child(1) {
        margin-left: auto;
      }
      .col-message:nth-child(3) {
        margin-left: auto;
      }
    }
    .col-deep-right {
      .col-message:nth-child(2) {
        margin-left: auto;
      }
    }
    .col-inner-content {
      max-width: 285px;
      margin-left: auto;
      margin-right: auto;
    }
    .col-inner-images {
      position: absolute;
      width: 430px;
      height: 430px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .deep-images {
      position: absolute;
      width: 100px;
      height: 100px;
    }
    .deep-images-1 {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .deep-images-2 {
      top: 54px;
      right: 56px;
    }
    .deep-images-3 {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    .deep-images-4 {
      right: 58px;
      bottom: 57px;
    }
    .deep-images-5 {
      right: 50%;
      bottom: 0;
      transform: translateX(50%);
    }
    .deep-images-6 {
      left: 58px;
      bottom: 57px
    }
    .deep-images-7 {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .deep-images-8 {
      top: 54px;
      left: 56px;
    }
    .deep-images-9 {
      width: 120px;
      height: 120px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        border: none;
      }
    }
    .col-message {
      max-width: 100%;
    }
    .col-message {
      margin-bottom: 40px;
    }
  }
  .sec-copyright-audio {
    .players {
      font-size: 0;
    }
    .players-slider,
    .play {
      display: inline-block;
      vertical-align: middle;
    }
    .round {
      margin-left: inherit;
      margin-right: inherit;
    }
  }
}

@media (min-width: $screen-sm-min) {
  .sec-meditation {
    .meditation-left {
      h2 {
        font-size: 46px;
      }
      p {
        font-size: 35px;
      }
    }
    .meditation-left,
    .meditation-center {
      margin-bottom: 20px;
    }
  }
  .sec-deep-sleep {
    padding-top: 160px;

    .deep-images {
      position: absolute;
      width: 120px;
      height: 120px;
    }
    .col-inner-images {
      width: 478px;
      height: 478px;
    }
    .deep-images-9 {
      width: 176px;
      height: 176px;
    }
  }
  .sec-features {
    .col-content-head {
      margin-bottom: 55px;
    }
    .features-cols {
      margin-bottom: 38px;
    }
    .features-image {
      margin-top: 60px;
    }
  }
  .sec-relaxion-pillow {
    .col-inner {
      font-size: 0;
    }
    .col-inner-content {
      display: inline-block;
      vertical-align: middle;

      &:first-child {
        position: relative;
        margin-bottom: 0;
        text-align: left;
      }
      &:last-child {
        margin-left: -80px;
        position: relative;

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          background: url('../img/deep-gradient.png') no-repeat;
          background-size: cover;
          background-position: 100%;
          position: absolute;
          z-index: 1;
          top: -6%;
          right: -60px;
        }
      }
      img {
        max-width: 280px;
      }
    }
    .col-content {
      background-color: $color-white;
      border-radius: 40px;
      padding: 30px 40px 30px 109px;
      max-width: 510px;
      margin-bottom: 20px;

      h4 {
        font-size: 25px;
      }
    }
  }
  .sec-appletv-support {
    .col-support-img {
      width: 276px;
      margin-bottom: 0;
    }
    .col-support-img,
    .col-support-text {
      float: left;
    }
    .col-support-text {
      width: calc(100% - 276px);
      padding-top: 63px;
      padding-left: 45px;
    }
  }
  .sec-apple-watch-support {
    padding-top: 230px;
  }
  .sec-copyright-audio {
    text-align: left;

    .players-stations {
      margin-bottom: 0;
    }
    .col-right {
      .col-inner {
        padding-left: 30px;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

@media (min-width: $screen-md-min) {
  .sec-meditation {
    margin-bottom: -206px;
    padding-top: 0;
    padding-bottom: 0;
    transform: translateY(-84px);

    .meditation-left,
    .meditation-right {
      .col-content {
        margin-top: -40px;
      }
    }
    .table {
      height: 910px;
    }
    .table-cell {
      vertical-align: middle;
      float: none;
    }
    .col-inner-icons {
      width: 463px;
      height: 463px;
      margin-top: -251px;
    }
    .meditation-left {
      h1 {
        font-size: 46px;
      }
      p {
        font-size: 35px;
      }
    }
    .col-inner-img img {
      max-width: 100%;
    }
    .meditation-left,
    .meditation-center {
      margin-bottom: 0;
    }
  }
  .sec-deep-sleep {
    padding-top: 187px;

    .col-deep-center {
      margin-bottom: 0;
    }
    .col-message {
      margin-bottom: 150px;
      max-width: 200px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sec-relaxion-pillow {
    .col-inner {
      max-width: 90%;
      margin-left: auto;
    }
  }
  .sec-apple-watch-support {
    padding-top: 330px;
  }
  .sec-appletv-support {
    .col-support-text {
      padding-left: 126px;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .sec-deep-sleep {
    .col-message {
      max-width: 300px;
    }
  }
  .sec-relaxion-pillow {
    .col-inner {
      max-width: 100%;
      margin-left: 0;
    }
    .col-inner-content {
      &:last-child {
        margin-left: -140px;
      }
      img {
        max-width: 100%;
      }
      &:after {
        top: -9%;
      }
    }
    .col-content {
      padding: 50px 50px 50px 160px;
      max-width: 806px;
      margin-bottom: 40px;

      h4 {
        font-size: 32px;
      }
    }
  }
  .sec-features {
    .features-cols {
      &:nth-child(2) {
        margin-left: 17.5%;
      }
      &:nth-child(3) {
        margin-left: 34.5%;
      }
      &:nth-child(4) {
        margin-left: 51.5%;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sec-apple-watch-support {
    padding-top: 430px;
  }
  .sec-copyright-audio {
    .players {
      font-size: 0;

    }
    .players-slider,
    .play,
    .players-stations,
    .players-audio  {
      display: inline-block;
      vertical-align: middle;
    }
    .players-audio {
      margin-bottom: 0;
    }
    .players-stations {
      margin-left: 45px;
    }
  }
}
