// Header
.header {
  min-height: 550px;
  padding-top: 18px;
  background-image: url('../img/header-bg.jpg');
  background-size: cover;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
    opacity: 0.7;
    z-index: 1;
  }
  .container {
    z-index: 2;
    position: relative;
  }
  .logo {
    margin-bottom: 20px;
    padding-top: 40px;

    a {
      text-decoration: none;
    }
  }
  .logo-img {
    display: block;
    margin-bottom: 20px;

    img {
      max-width: 148px;
    }
  }
  .logo-txt h1 {
    font-family: $font-family-second;
    font-size: 48px;
    margin: 0;
    color: $color-black;
    font-weight: 300;
  }
  .col-content {
    margin-bottom: 37px;
  }
  .text-right {
    overflow: hidden;
  }
}
.header-logins {
  padding-bottom: 60px;
}

// Responsive
@media (max-width: $screen-ms-max) {
  .header {
    .header-phones {
      margin-top: 40px;
      text-align: center;
    }
  }
}
@media (min-width: $screen-md-min) {
  .header {
    min-height: 850px;

    .logo {
      margin-bottom: 45px;
    }
    .col-content {
      margin-bottom: 57px;
    }
  }
}
@media (min-width: $screen-lg-min) {
  .header {
    .header-phones img {
      margin-right: -30px;
    }
  }
}
@media (min-width: $screen-xl-min) {
  .header {
    .header-phones img {
      margin-right: -79px;
    }
  }
}
