// Columns Center;
.col-center {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

// Coll midle
@media (min-width: $screen-sm-min) {
  .col-middle-center {
    display: inline-block;
    vertical-align: middle;
    float: none;
  }
}
