// Payment
.reg-subscription {
  .col-xs-12 {
    font-size: 0;
  }
  .col-inner {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
  }
  hr {
    border-color: #dedede;
  }
  .bar-top {
    margin-top: 0;
    margin-bottom: 18px;
  }
  .bar-bottom {
    margin-top: 17px;
    margin-bottom: 10px;
  }
}
