// Globals Variables

// Font Family
$font-family-base: 'bariol';
$font-family-second: 'aileron';

// Font size
$font-size-base: 16px;

// Color
$color-white: #fff;
$color-black: #000;
$price-best-color: #ffae00;
$color-green: #54d481;

// Mid-Small screen / small tablets and new cellphones
$screen-ms-min:              480px !default;

// Small screen / tablet
$screen-sm-min:              768px !default;

// Medium screen / desktop
$screen-md-min:              992px !default;

// Large screen / wide desktop
$screen-lg-min:              1200px !default;

// Extra Large screen
$screen-xl-min:              1400px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:             ($screen-ms-min - 1) !default;
$screen-ms-max:             ($screen-sm-min - 1) !default;
$screen-sm-max:             ($screen-md-min - 1) !default;
$screen-md-max:             ($screen-lg-min - 1) !default;
$screen-lg-max:             ($screen-xl-min - 1) !default;

// Brand Colors
$fa-500px: #0099e5;
$fa-adn: #4A484C;
$fa-amazon: #ff9900;
$fa-android: #a4c639;
$fa-angellist: $color-black;
$fa-apple: #979797;
$fa-bitbucket: #205081;
$fa-behance: #1769ff;
$fa-behance-square: $fa-behance;
$fa-bitbucket-square: $fa-bitbucket;
$fa-bitcoin: #ee9209;
$fa-black-tie: #222;
$fa-btc: $fa-bitcoin;
$fa-buysellads: #c90100;
$fa-cc-amex: #007bc1;
$fa-cc-diners-club: #004A97;
$fa-cc-discover: #f68121;
$fa-cc-jcb: #003A8F;
$fa-cc-mastercard: #0a3a82;
$fa-cc-paypal: #253b80;
$fa-cc-stripe: #00afe1;
$fa-cc-visa: #0157a2;
$fa-chrome: #4587F3;
$fa-codepen: $color-black;
$fa-connectdevelop: #391448;
$fa-contao: #eb8623;
$fa-creative-commons: #231f20;
$fa-css3: #1680C0;
$fa-dashcube: #7f7f7f;
$fa-delicious: #3399ff;
$fa-deviantart: #4e6252;
$fa-digg: $color-black;
$fa-dribbble: #444444;
$fa-dropbox: #007ee5;
$fa-drupal: #0077c0;
$fa-empire: $color-black;
$fa-expeditedssl: #343433;
$fa-facebook: #3b5998;
$fa-facebook-official: $fa-facebook;
$fa-facebook-square: $fa-facebook;
$fa-firefox: #e66000;
$fa-flickr: #ff0084;
$fa-fonticons: #1C1E29;
$fa-forumbee: #83ad13;
$fa-foursquare: #0072b1;
$fa-ge: $color-black;
$fa-get-pocket: #d3505a;
$fa-gg-circle: $color-black;
$fa-gg: $color-black;
$fa-git: #333;
$fa-github: $fa-git;
$fa-git-square: $fa-git;
$fa-github-alt: $fa-github;
$fa-github-square: $fa-github;
$fa-gittip: #663300;
$fa-google: #4285f4;
$fa-google-plus: #dd4b39;
$fa-google-plus-square: $fa-google-plus;
$fa-google-wallet: $fa-google;
$fa-hacker-news: #ff6600;
$fa-houzz: #7ac142;
$fa-html5: #e34f26;
$fa-instagram: #000; // #3f729b
$fa-internet-explorer: #1EBBEE;
$fa-ioxhost: #faa729;
$fa-joomla: #142849;
$fa-jsfiddle: #4679BD;
$fa-lastfm: #c3000d;
$fa-lastfm-square: $fa-lastfm;
$fa-leanpub: #0c0c0c;
$fa-linkedin: #0976b4;
$fa-linkedin-square: $fa-linkedin;
$fa-linux: #333333;
$fa-maxcdn: #FF6600;
$fa-meanpath: #538ed7;
$fa-medium: $color-black;
$fa-odnoklassniki: #ed812b;
$fa-odnoklassniki-square: $fa-odnoklassniki;
$fa-opencart: #2AC2EF;
$fa-openid: #F78C40;
$fa-opera: #cc0f16;
$fa-optin-monster: #83c11f;
$fa-pagelines: $color-black;
$fa-paypal: #253b80;
$fa-pied-piper: #2f9f46;
$fa-pinterest: #cc2127;
$fa-pied-piper-alt: $fa-pied-piper;
$fa-pied-piper-square: $fa-pied-piper;
$fa-pinterest-p: $fa-pinterest;
$fa-pinterest-square: $fa-pinterest;
$fa-qq: $color-black;
$fa-ra: $color-black;
$fa-reddit: #ff4500;
$fa-rebel: $fa-ra;
$fa-reddit-square: $fa-reddit;
$fa-renren: #005EAC;
$fa-safari: #1B88CA;
$fa-sellsy: #1f78b9;
$fa-share-alt: #01bf01;
$fa-share-alt-square: $fa-share-alt;
$fa-shirtsinbulk: #dd3a26;
$fa-simplybuilt: $color-black;
$fa-skyatlas: #00adbb;
$fa-skype: #00aff0;
$fa-slack: #0f7965;
$fa-slideshare: #e98325;
$fa-soundcloud: #f80;
$fa-spotify: #7ab800;
$fa-stack-exchange: $color-black;
$fa-stack-overflow: #fe7a15;
$fa-steam: #0B0B0B;
$fa-steam-square: $fa-steam;
$fa-stumbleupon: #eb4924;
$fa-stumbleupon-circle: $fa-stumbleupon;
$fa-tencent-weibo: #74AF2C;
$fa-trello: #256a92;
$fa-tripadvisor: #589442;
$fa-tumblr: #35465c;
$fa-tumblr-square: $fa-tumblr;
$fa-twitch: #6441a5;
$fa-twitter: #55acee;
$fa-twitter-square: $fa-twitter;
$fa-viacoin: #333;
$fa-vimeo: #1ab7ea;
$fa-vimeo-square: $fa-vimeo;
$fa-vine: #00b488;
$fa-vk: #45668e;
$fa-wechat: #93d034;
$fa-weibo: #E71D34;
$fa-weixin: $fa-wechat;
$fa-wikipedia-w: $color-black;
$fa-windows: #00bcf2;
$fa-wordpress: #21759b;
$fa-xing: #026466;
$fa-xing-square: $fa-xing;
$fa-y-combinator: #F0652F;
$fa-yahoo: #400191;
$fa-yc: $fa-y-combinator;
$fa-yelp: #af0606;
$fa-youtube: #e52d27;
$fa-youtube-play: $fa-youtube;
$fa-youtube-square: $fa-youtube;
