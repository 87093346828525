// Logins & registration
.reg-content {
  p {
    color: #464646
  }
  a {
    color: $color-green;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
      color: darken($color-green, 10%);
    }
  }
}
.log-content-title {
  font-size: 24px;
  color: #464646;
  margin-bottom: 13px;
}
.reg-forms {
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  form {
    font-size: 16px;
    margin-bottom: 4px;
  }
}
.reg-forms-socials {
  margin-top: 20px;
  font-size: 20px;

  p {
    margin-bottom: 24px;
  }
}

// Forget Status
.reg-forget-status {
  margin-top: 32px;
  font-size: 24px;
}

// Responsive
@media (min-width: $screen-md-min) {
  .reg-forms {
    margin-top: 96px;
  }
}
