.fa {
  // Default color for undefined
  color: #efefef;

  &.fa-500px {
    color: $fa-500px;
  }
  &.fa-adn {
    color: $fa-adn;
  }
  &.fa-amazon {
    color: $fa-amazon;
  }
  &.fa-android {
    color: $fa-android;
  }
  &.fa-angellist {
    color: $fa-angellist;
  }
  &.fa-apple {
    color: $fa-apple;
  }
  &.fa-behance {
    color: $fa-behance;
  }
  &.fa-behance-square {
    color: $fa-behance-square;
  }
  &.fa-bitbucket {
    color: $fa-bitbucket;
  }
  &.fa-bitbucket-square {
    color: $fa-bitbucket-square;
  }
  &.fa-bitcoin {
    color: $fa-bitcoin;
  }
  &.fa-black-tie {
    color: $fa-black-tie;
  }
  &.fa-btc {
    color: $fa-btc;
  }
  &.fa-buysellads {
    color: $fa-buysellads;
  }
  &.fa-cc-amex {
    color: $fa-cc-amex;
  }
  &.fa-cc-diners-club {
    color: $fa-cc-diners-club;
  }
  &.fa-cc-discover {
    color: $fa-cc-discover;
  }
  &.fa-cc-jcb {
    color: $fa-cc-jcb;
  }
  &.fa-cc-mastercard {
    color: $fa-cc-mastercard;
  }
  &.fa-cc-paypal {
    color: $fa-cc-paypal;
  }
  &.fa-cc-stripe {
    color: $fa-cc-stripe;
  }
  &.fa-cc-visa {
    color: $fa-cc-visa;
  }
  &.fa-chrome {
    color: $fa-chrome;
  }
  &.fa-codepen {
    color: $fa-codepen;
  }
  &.fa-connectdevelop {
    color: $fa-connectdevelop;
  }
  &.fa-contao {
    color: $fa-contao;
  }
  &.fa-creative-commons  {
    color: $fa-creative-commons;
  }
  &.fa-css3 {
    color: $fa-css3;
  }
  &.fa-dashcube {
    color: $fa-dashcube;
  }
  &.fa-delicious {
    color: $fa-delicious;
  }
  &.fa-deviantart {
    color: $fa-deviantart;
  }
  &.fa-digg {
    color: $fa-digg;
  }
  &.fa-dribbble {
    color: $fa-dribbble;
  }
  &.fa-dropbox {
    color: $fa-dropbox;
  }
  &.fa-drupal {
    color: $fa-drupal;
  }
  &.fa-empire {
    color: $fa-empire;
  }
  &.fa-expeditedssl {
    color: $fa-expeditedssl;
  }
  &.fa-facebook {
    color: $fa-facebook;
  }
  &.fa-facebook-official {
    color: $fa-facebook-official;
  }
  &.fa-facebook-square {
    color: $fa-facebook-square;
  }
  &.fa-firefox {
    color: $fa-firefox;
  }
  &.fa-flickr {
    color: $fa-flickr;
  }
  &.fa-fonticons {
    color: $fa-fonticons;
  }
  &.fa-forumbee {
    color: $fa-forumbee;
  }
  &.fa-foursquare {
    color: $fa-foursquare;
  }
  &.fa-ge {
    color: $fa-ge;
  }
  &.fa-get-pocket {
    color: $fa-get-pocket;
  }
  &.fa-gg {
    color: $fa-gg;
  }
  &.fa-gg-circle {
    color: $fa-gg-circle;
  }
  &.fa-git {
    color: $fa-git;
  }
  &.fa-git-square {
    color: $fa-git-square;
  }
  &.fa-github {
    color: $fa-github;
  }
  &.fa-github-alt {
    color: $fa-github-alt;
  }
  &.fa-github-square {
    color: $fa-github-square;
  }
  &.fa-gittip {
    color: $fa-gittip;
  }
  &.fa-google {
    color: $fa-google;
  }
  &.fa-google-plus {
    color: $fa-google-plus;
  }
  &.fa-google-plus-square {
    color: $fa-google-plus-square;
  }
  &.fa-google-wallet {
    color: $fa-google-wallet;
  }
  &.fa-hacker-news {
    color: $fa-hacker-news;
  }
  &.fa-houzz  {
    color: $fa-houzz;
  }
  &.fa-html5 {
    color: $fa-html5;
  }
  &.fa-instagram {
    color: $fa-instagram;
  }
  &.fa-internet-explorer {
    color: $fa-internet-explorer;
  }
  &.fa-ioxhost {
    color: $fa-ioxhost;
  }
  &.fa-joomla {
    color: $fa-joomla;
  }
  &.fa-jsfiddle {
    color: $fa-jsfiddle;
  }
  &.fa-lastfm {
    color: $fa-lastfm;
  }
  &.fa-lastfm-square {
    color: $fa-lastfm-square;
  }
  &.fa-leanpub {
    color: $fa-leanpub;
  }
  &.fa-linkedin {
    color: $fa-linkedin;
  }
  &.fa-linkedin-square {
    color: $fa-linkedin-square;
  }
  &.fa-linux {
    color: $fa-linux;
  }
  &.fa-maxcdn {
    color: $fa-maxcdn;
  }
  &.fa-meanpath {
    color: $fa-meanpath;
  }
  &.fa-medium {
    color: $fa-medium;
  }
  &.fa-odnoklassniki  {
    color: $fa-odnoklassniki;
  }
  &.fa-odnoklassniki-square  {
    color: $fa-odnoklassniki-square;
  }
  &.fa-opencart {
    color: $fa-opencart;
  }
  &.fa-openid {
    color: $fa-openid;
  }
  &.fa-opera  {
    color: $fa-opera;
  }
  &.fa-optin-monster {
    color: $fa-optin-monster;
  }
  &.fa-pagelines {
    color: $fa-pagelines;
  }
  &.fa-paypal {
    color: $fa-paypal;
  }
  &.fa-pied-piper {
    color: $fa-pied-piper;
  }
  &.fa-pied-piper-alt {
    color: $fa-pied-piper-alt;
  }
  &.fa-pied-piper-square {
    color: $fa-pied-piper-square;
  }
  &.fa-pinterest {
    color: $fa-pinterest;
  }
  &.fa-pinterest-p {
    color: $fa-pinterest-p;
  }
  &.fa-pinterest-square {
    color: $fa-pinterest-square;
  }
  &.fa-qq {
    color: $fa-qq;
  }
  &.fa-ra {
    color: $fa-ra;
  }
  &.fa-rebel {
    color: $fa-rebel;
  }
  &.fa-reddit {
    color: $fa-reddit;
  }
  &.fa-reddit-square {
    color: $fa-reddit-square;
  }
  &.fa-renren {
    color: $fa-renren;
  }
  &.fa-safari  {
    color: $fa-safari;
  }
  &.fa-sellsy {
    color: $fa-sellsy;
  }
  &.fa-share-alt {
    color: $fa-share-alt;
  }
  &.fa-share-alt-square {
    color: $fa-share-alt-square;
  }
  &.fa-shirtsinbulk {
    color: $fa-shirtsinbulk;
  }
  &.fa-simplybuilt {
    color: $fa-simplybuilt;
  }
  &.fa-skyatlas {
    color: $fa-skyatlas;
  }
  &.fa-skype {
    color: $fa-skype;
  }
  &.fa-slack {
    color: $fa-slack;
  }
  &.fa-slideshare {
    color: $fa-slideshare;
  }
  &.fa-soundcloud {
    color: $fa-soundcloud;
  }
  &.fa-spotify {
    color: $fa-spotify;
  }
  &.fa-stack-exchange {
    color: $fa-stack-exchange;
  }
  &.fa-stack-overflow {
    color: $fa-stack-overflow;
  }
  &.fa-steam {
    color: $fa-steam;
  }
  &.fa-steam-square {
    color: $fa-steam-square;
  }
  &.fa-stumbleupon {
    color: $fa-stumbleupon;
  }
  &.fa-stumbleupon-circle {
    color: $fa-stumbleupon-circle;
  }
  &.fa-tencent-weibo {
    color: $fa-tencent-weibo;
  }
  &.fa-trello {
    color: $fa-trello;
  }
  &.fa-tripadvisor  {
    color: $fa-tripadvisor;
  }
  &.fa-tumblr {
    color: $fa-tumblr;
  }
  &.fa-tumblr-square {
    color: $fa-tumblr-square;
  }
  &.fa-twitch {
    color: $fa-twitch;
  }
  &.fa-twitter {
    color: $fa-twitter;
  }
  &.fa-twitter-square {
    color: $fa-twitter-square;
  }
  &.fa-viacoin {
    color: $fa-viacoin;
  }
  &.fa-vimeo  {
    color: $fa-vimeo;
  }
  &.fa-vimeo-square {
    color: $fa-vimeo-square;
  }
  &.fa-vine {
    color: $fa-vine;
  }
  &.fa-vk {
    color: $fa-vk;
  }
  &.fa-wechat {
    color: $fa-wechat;
  }
  &.fa-weibo {
    color: $fa-weibo;
  }
  &.fa-weixin {
    color: $fa-weixin;
  }
  &.fa-wikipedia-w {
    color: $fa-wikipedia-w;
  }
  &.fa-windows {
    color: $fa-windows;
  }
  &.fa-wordpress {
    color: $fa-wordpress;
  }
  &.fa-xing {
    color: $fa-xing;
  }
  &.fa-xing-square {
    color: $fa-xing-square;
  }
  &.fa-y-combinator {
    color: $fa-y-combinator;
  }
  &.fa-yc {
    color: $fa-yc;
  }
  &.fa-yahoo {
    color: $fa-yahoo;
  }
  &.fa-yelp {
    color: $fa-yelp;
  }
  &.fa-youtube {
    color: $fa-youtube;
  }
  &.fa-youtube-play {
    color: $fa-youtube-play;
  }
  &.fa-youtube-square {
    color: $fa-youtube-square;
  }
}
