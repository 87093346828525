// Registration Page
.reg-wrapper {
  color: $color-black;

  section {
    padding-top: 0;
    padding-bottom: 0;
  }
}
// Header
.wrap-header {
  box-shadow: 0px 4px 7px 0px rgba(24, 24, 25, 0.11);
  padding-top: 28px;
  padding-bottom: 28px;
  margin-bottom: 30px;
}
.reg-logo {
  font-size: 0;

  &:hover {
    color: $color-black;
    text-decoration: none;
  }
}
.reg-logo-img {
  display: inline-block;

  img {
    max-width: 67px;
  }
}
.reg-logo-img,
.reg-logo-title {
  display: inline-block;
  vertical-align: middle;
}
.reg-logo-title {
  padding-left: 30px;

  h1 {
    font-family: $font-family-second;
    font-size: 32px;
    color: $color-black;
  }
}

// Bars
.reg-bars-title {
  margin-bottom: 40px;

  h2 {
    font-size: 40px;
    color: #232323;
  }
}

// Price
.reg-pricing {
  margin-bottom: 20px;
  margin-top: 90px;

  .col-inner {
    border: 1px solid #d7d7d7;
    padding-left: 27px;
    padding-right: 27px;
    padding-top: 26px;
    padding-bottom: 26px;
    border-radius: 7px;
    transition: box-shadow 0.2s linear;
    margin-bottom: 20px;

    &:hover {
      transition: box-shadow 0.2s linear;
      box-shadow: 0px 11px 46px 0px rgba(24, 24, 25, 0.17);
    }
  }
  .reg-price-star {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 47px;
  }
  .btn {
    font-weight: 400;
  }
}
.reg-price-star {
  .reg-price {
    text-align: left;
  }
}
.reg-price-title {
  font-size: 24px;
}
.reg-price-devider {
  margin-top: 15px;
}
.reg-price {
  font-size: 38px;
  margin-bottom: 26px;

  sup {
    font-size: 20px;
    padding-right: 8px;
  }
  sub {
    font-size: 18px;
    bottom: 0;
  }
}
.reg-price-best {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  height: 48px;
  background-color: $price-best-color;
  color: $color-white;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  h4 {
    font-size: 24px;
    line-height: 48px;
  }
}
.reg-price-trought {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  color: #999999;
  font-size: 29px;

  sup {
    font-size: 20px;
    line-height: 1;
    top: -5px;
    padding-right: 4px;
  }
  &:before {
    content: '';
    left: 0;
    right: -5px;
    height: 2px;
    position: absolute;
    top: 50%;
    background-color: #999999;
  }
}
.reg-price-big {
  display: inline-block;
  vertical-align: middle;
  padding-left: 40px;
}
.reg-guaranteed {
  color: #505050;
  margin-bottom: 40px;

  .sprites {
    margin-right: 16px;
  }
}
.reg-features {
  margin-bottom: 25px;

  .col-xs-6 {
    margin-bottom: 30px;
  }
  p {
    line-height: 1.1;
    color: #3e3e3e;
  }
}
.reg-spr-head {
  min-height: 44px;
  position: relative;
  margin-bottom: 13px;

  .sprites {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.reg-spr-1 {
  width: 37px;
  height: 35px;
  background-position: -682px 0;
}
.reg-spr-2 {
  width: 34px;
  height: 33px;
  background-position: -720px 0;
}
.reg-spr-3 {
  width: 38px;
  height: 39px;
  background-position: -757px 0;
}
.reg-spr-4 {
  width: 45px;
  height: 44px;
  background-position: -797px 0;
}
.reg-spr-5 {
  width: 41px;
  height: 37px;
  background-position: -844px 0;
}
.reg-spr-6 {
  width: 47px;
  height: 21px;
  background-position: -887px 0;
}

// Footer
.reg-footer-title {
  font-size: 24px;
  margin-bottom: 50px;
}
.reg-footer-features > {
  .col-xs-12 {
    margin-bottom: 30px;
  }
}
.reg-footer-col-2 {
  .col-inner {
    font-size: 0;
    margin: -5px -4px 9px;
  }
}
.reg-footer-img {
  display: inline-block;
  vertical-align: middle;
  margin: 5px 3px 0;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  overflow: hidden;
}
.reg-col-2-footer {
  color: #505050;
}
.col-wrap {
  font-size: 0;
  margin-left: -12px;
  margin-right: -12px;

  .col-inner {
    display: inline-block;
    vertical-align: middle;
    padding-left: 9px;
    padding-right: 9px;
  }
}

// Slider
.reg-footer-col-4 {
  .owl-theme .owl-dots {
    margin-top: 30px !important;

    .owl-dot {
      span {
        background-color: #cccccc;
        margin-left: 3px;
        margin-right: 3px;
        transition: background-color 0.2s linear;
      }

      &.active,
      &:hover {
        span {
          background-color: $color-black;
          transition: background-color 0.2s linear;
        }
      }
    }
  }
}
.testimonial-img,
.testimonial-content {
  float: left;
}
.testimonial-img {
  max-width: 50px;
  width: 15%;
  padding-top: 5px;

  img {
    max-width: 41px;
  }
}
.testimonial-content {
  width: 85%;
  padding-left: 18px;

  p {
    font-size: 20px;
  }
}
// Copyright
.reg-footer-copyright {
  margin-top: 40px;
  margin-bottom: 40px;
}

// Recovery Passord
.reg-recovery-status {
  margin-top: 40px;
  font-size: 20px;
}

// Responsive
@media (min-width: $screen-sm-min) {
  .reg-footer-features {
    > .col-xs-12:not(:last-child):not(:nth-child(2)) {
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 292px;
        top: 0;
        right: 0;
        background: url('../img/reg-separator.png') no-repeat;
      }
    }
  }
}
@media (min-width: $screen-md-min) {
  .wrap-header {
    margin-bottom: 75px;
  }
  .reg-bars-title {
    margin-bottom: 55px;
  }
  .reg-pricing {
    margin-top: 150px;

    .col-inner {
      margin-bottom: 0;
    }
    .reg-price-star {
      margin-top: 0;
    }
    .reg-price-best {
      top: -47px;
    }
  }
  .reg-features {
    margin-bottom: 76px;

    .col-xs-6 {
      margin-bottom: 0;
    }
  }
  .reg-guaranteed {
    margin-bottom: 80px;
  }
}

@media(min-width: $screen-lg-min) {
  .reg-footer-features {
    > .col-xs-12:not(:last-child) {
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 292px;
        top: 0;
        right: 0;
        background: url('../img/reg-separator.png') no-repeat;
      }
    }
  }
  .reg-footer-copyright {
    margin-top: 100px;
  }
}
