/* Bariol */
@font-face {
    font-family: 'bariol';
    src: url('fonts/font/bariol/bariol_bold_italic-webfont.woff2') format('woff2'),
         url('fonts/font/bariol/bariol_bold_italic-webfont.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'bariol';
    src: url('fonts/font/bariol/bariol_bold-webfont.woff2') format('woff2'),
         url('fonts/font/bariol/bariol_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'bariol';
    src: url('fonts/font/bariol/bariol_light_italic-webfont.woff2') format('woff2'),
         url('fonts/font/bariol/bariol_light_italic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'bariol';
    src: url('fonts/font/bariol/bariol_light-webfont.woff2') format('woff2'),
         url('fonts/font/bariol/bariol_light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'bariol';
    src: url('fonts/font/bariol/bariol_regular_0-webfont.woff2') format('woff2'),
         url('fonts/font/bariol/bariol_regular_0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'bariol';
    src: url('fonts/font/bariol/bariol_regular_italic-webfont.woff2') format('woff2'),
         url('fonts/font/bariol/bariol_regular_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
