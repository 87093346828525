// Text Align
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-xs-right {
  text-align: right !important;
}

.animate {
  visibility: hidden;

  &.animated {
    visibility: visible;
  }
}

// Responsive
@media (min-width: $screen-sm-min) {
  .text-right {
    text-align: right !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
