// Icons
.sprites {
  display: block;
  background: url('../img/sprites.png') 0 0 no-repeat;
}
.icon-guaranteed {
  background-position: -664px 0;
  width: 16px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
