$mw: 570px;
$barBg: #f2f2f2;
$barBorder: #e8e8e8;
$barBgValue: #464646;
$barHeight: 20px;

// ProgressBar
#progressbar {
  border-color: $barBorder;
  background-color: $barBg;
  max-width: $mw;
  height: $barHeight;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;

  .ui-progressbar-value {
    border: none;
    margin: 0;
    background-color: $barBgValue;
    border-radius: 30px;
    transition: width 0.2s linear;
  }
}

// Steps
.reg-steps {
  font-size: 0;
  line-height: 1;
  text-align: justify;
  max-width: $mw;
  margin-left: auto;
  margin-right: auto;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 0;
  }
}
.reg-step {
  display: inline-block;
  vertical-align: middle;

  &.active p {
    color: 464646;
    font-weight: bold;
  }
  p {
    font-size: 18px;
    color: #adadad;
  }
}
