// Footer
.footer {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #1f2022;

  .footer-logo {
    margin-bottom: 30px;

    a {
      text-decoration: none;
    }
    img {
      max-width: 68px;
      margin-bottom: 15px;
    }
    h6 {
      font-family: $font-family-second;
      font-size: 20px;
      color: $color-white;
      font-weight: 300;
    }
  }
  .navs {
    margin-left: -12px;
    margin-right: -12px;
    font-size: 0;
    margin-bottom: 30px;

    li {
      display: inline-block;
      vertical-align: middle;
      padding-left: 12px;
      padding-right: 12px;
    }
    a {
      text-decoration: none;
      color: #858585;
      font-size: 16px;

      &:hover,
      &.active {
        color: $color-white;
      }
      &:focus {
        color: #858585;
      }
    }
  }
  .socials {
    font-size: 0;
    margin-left: -15px;
    margin-right: -15px;

    li {
      display: inline-block;
      vertical-align: middle;
      padding-left: 15px;
      padding-right: 15px;
    }
    a {
      display: block;
      text-decoration: none;
      width: 38px;
      height: 38px;
      background-color: #38393c;
      border-radius: 38px;
      text-align: center;
      font-size: 16px;
      line-height: 38px;
      color: $color-white;

      &:hover {
        &.fa-facebook {
          background-color: $fa-facebook;
        }
        &.fa-twitter {
          background-color: $fa-twitter;
        }
        &.fa-youtube-play {
          background-color: $fa-youtube-play;
        }
        &.fa-vimeo {
          background-color: $fa-vimeo;
        }
      }
    }
  }
}

// Responsive
@media (min-width: $screen-md-min) {
  .footer {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
