// Reset
body {
  font-family: $font-family-base;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
section {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
}

// Navs
ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Headins
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  line-height: 1;
}

a {
  transition: all 0.2s linear;

  &:hover {
    transition: all 0.2s linear;
  }
}

// Paragraph
p {
  margin: 0;
}

// Images
img {
  max-width: 100%;
}

// Inputs
::-webkit-input-placeholder {
  color: $color-white
}
:-moz-placeholder {
  color: $color-white;
}
::-moz-placeholder {
  color: $color-white;
}
:-ms-input-placeholder {
  color: $color-white;
}
input[type="text"] {
  color: $color-white;
  font-size: $font-size-base;
  border: none;
  outline: none;
}

// Social
.socials {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

// Table
.table {
  display: table;
  position: relative;
  width: 100%;
}
.table-cell {
  display: table-cell;
}

// Animation
.animated {
  visibility: hidden;

  &.animate {
    visibility: visible;
  }
}

// Popups
.white-popup-block {
  background: #FFF;
  padding: 20px 0 20px 30px;
  text-align: left;
  max-width: 650px;
  max-height: 325px;
  margin: 40px auto;
  position: relative;
}

// Responsive
@media(min-width: $screen-md-min) {
  section {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
