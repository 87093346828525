// Buttons
// Animation
$btn-transition-speed: 0.28s !default;
$btn-focus-color: #000;

// Apple Buttons
.buttons-app {
  width: 210px;
  height: 62px;
  background: url('../img/apple-button.png') no-repeat;
  display: inline-block;
}

// Btn
.btn {
  font-family: $font-family-base;
  font-weight: 700;
  text-decoration: none !important;
}
.btn-green {
  background-color: $color-green !important;
  color: #fff !important;
  border-color: $color-green !important;

  &:hover {
    color: #fff !important;
    background-color: #39b966 !important;
    border-color: #39b966 !important;
  }
}
.btn-grey {
  background-color: #bbbbbb;
  color: #fff !important;

  &:hover {
    background-color: #999;
  }
}
.btn-fluid {
  width: 100%;
}
.btn-round {
  border-radius: 50px;
}
.btn-md {
  font-size: 16px;
  padding-top: 7px;
  padding-bottom: 7px;
}
.btn-lg {
  font-size: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.btn-xl {
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 24px;
}
.btn-width-1 {
  min-width: 170px;
}

// Types
.btn-facebook {
  background-color: $fa-facebook;
  color: $color-white !important;
  font-size: 20px;
  position: relative;
  padding-top: 11px;
  padding-bottom: 11px;

  i {
    position: absolute;
    font-size: 24px;
    top: 50%;
    left: 28px;
    transform: translateY(-50%);
    color: $color-white !important;
  }
  &:hover {
    background-color: darken($fa-facebook, 10%);
  }
}

.btn-animate {
  overflow: hidden;
  outline: none;
  cursor: pointer;
  transition: background-color $btn-transition-speed ease, color $btn-transition-speed ease, box-shadow $btn-transition-speed ease;
  overflow: hidden;
  position: relative;

  span {
    color: #fff;
    position: relative;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    background: lighten($btn-focus-color, 50%);
    border: 50vh solid lighten($btn-focus-color, 20%);
    width: 30vh;
    height: 30vh;
    border-radius: 50%;
    display: block;
    top: 50%;
    left: 50%;
    z-index: 0;
    opacity: 1;
    transform: translate(-50%, -50%) scale(0);
  }

  &:active,
  &:focus {
    &::before {
      transition: transform ($btn-transition-speed * 4) ease, opacity $btn-transition-speed ease ($btn-transition-speed * 1.3);
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }

  &:focus {
    outline: none;
  }
}
